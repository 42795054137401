<template>
  <div>
    <app-header>
      <template v-slot:top>
        <div id="particles">
        </div>
      </template>

      <div class="container">
        <h2 class="subtitle">&mdash; Creativity &mdash;</h2>
        <h1
          class="title animated"
          data-animated="expanding-letters-fade-out"
          data-animated-reverse="expanding-letters-fade-in"
          data-offset="20"
        >Unleashed</h1>
      </div>
    </app-header>

    <section class="section is-fullwidth is-variant1">
      <div class="columns is-centered">
        <div class="column is-8">
          <div class="columns">
            <div class="column has-text-centered">
              <h2 class="title">What we offer</h2>
              <p>
                Vihanti specializes in mobile apps, web development, and
                backend cloud software. We provide uncompromising service by
                having our American client management based in India, side by
                side with our team of experienced developers. We bridge the
                communication gap for you, so that you can be assured that we
                will deliver what you want at a quality that exceeds what you
                expect.
              </p>
            </div>
          </div>

          <div class="columns services">
            <div class="column">
              <div class="box content animated" data-animated="fadeInLeft">
                <img src="@/assets/services/frontend.png" alt />
                <h3 class="subtitle">
                  <strong>Frontend</strong>
                </h3>
                <p>
                  We pride ourselves in making modern frontend technology available to
                  businesses of all sizes, whether you need a new website or a
                  mobile-first design refresh.
                </p>
                <p>
                  <router-link to="/services">
                    <b-icon icon="arrow-right" /> Learn More
                  </router-link>
                </p>
              </div>
            </div>
            <div class="column lower">
              <div class="box content animated" data-animated="fadeInUp">
                <img src="@/assets/services/backend.png" alt />
                <h3 class="subtitle">
                  <strong>Backend</strong>
                </h3>
                <p>
                  We love building reliable, high performance systems to
                  solve real world problems using the latest devops
                  technologies across a wide range of languages and frameworks.
                </p>
                <p>
                  <router-link to="/services">
                    <b-icon icon="arrow-right" /> Learn More
                  </router-link>
                </p>
              </div>
            </div>
            <div class="column">
              <div class="box content animated" data-animated="fadeInRight">
                <img src="@/assets/services/embedded.png" alt />
                <h3 class="subtitle">
                  <strong>Embedded</strong>
                </h3>
                <p>
                  With years of experience developing on cutting-edge mobile
                  chipsets, we are here to help solve the most challenging
                  cross-domain problems facing you today.
                </p>
                <p>
                  <router-link to="/services">
                    <b-icon icon="arrow-right" /> Learn More
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="inthenews section is-fullwidth is-hidden">
      <div class="columns is-centered">
        <div class="column is-three-quarters">
          <div class="content has-text-centered">
            <h2 class="title">In the news</h2>
          </div>

          <div class="column">
            <div class="columns is-vcentered">
              <div class="column is-three-quarters">
                We're privileged to be a part of what <a
                href="https://truepic.com/">Truepic</a>, <a
                href="https://www.nytimes.com/">The New York Times</a> and
                the <a href="https://contentauthenticity.org/">Content
                Authenticity Initiative</a> are doing to combat
                misinformation.
              </div>

              <div class="column is-one-quarter">
                <a href="https://rd.nytimes.com/projects/using-secure-sourcing-to-combat-misinformation"
                  class="button is-rounded is-dark is-small">Read More</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="questions section is-fullwidth is-variant2">
      <div class="columns is-centered">
        <div class="column is-8">
          <div class="content has-text-centered">
            <h2 class="title">How we can help you</h2>
            <p>
              <span class="tag is-rounded is-medium">Ask yourself these questions</span>
            </p>
          </div>

          <div class="columns is-centered">
            <div class="column box is-8">
              <b-carousel>
                <b-carousel-item v-for="(question, i) in questions" :key="i">
                  <section class="hero">
                    <div class="hero-body has-text-centered">{{ question }}</div>
                  </section>
                </b-carousel-item>
              </b-carousel>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="tech section is-fullwidth is-variant3">
      <div class="columns is-centered">
        <div class="column is-8">
          <div class="content has-text-centered">
            <h2 class="title">How we do it</h2>
            <p>Here's a sampling of some of our favorite technologies:</p>
          </div>

          <div class="columns logos">
            <div class="column has-text-centered animated" data-animated="fadeIn">
              <a href="https://flutter.dev/">
                <div class="box">
                  <img src="@/assets/tech/flutter.png" alt="Flutter" />
                </div>
              </a>
            </div>
            <div class="column has-text-centered animated" data-animated="fadeIn">
              <a href="https://www.shopify.com/">
                <div class="box">
                  <img src="@/assets/tech/shopify.png" alt="Shopify" />
                </div>
              </a>
            </div>
            <div class="column has-text-centered animated" data-animated="fadeIn">
              <a href="https://nodejs.org/">
                <div class="box">
                  <img src="@/assets/tech/nodejs.png" alt="Node.js" />
                </div>
              </a>
            </div>
            <div class="column has-text-centered animated" data-animated="fadeIn">
              <a href="https://www.python.org/">
                <div class="box">
                  <img src="@/assets/tech/python.png" alt="Python" />
                </div>
              </a>
            </div>
            <div class="column has-text-centered animated" data-animated="fadeIn">
              <a href="https://firebase.google.com/">
                <div class="box">
                  <img src="@/assets/tech/firebase.png" alt="Firebase" />
                </div>
              </a>
            </div>
          </div>

          <p class="has-text-centered">
            <router-link
              to="/services"
              class="button is-rounded is-small is-info"
            >Click here to learn more</router-link>
          </p>
        </div>
      </div>
    </section>
    <section class="contact section is-fullwidth is-variant4">
      <div class="columns is-centered">
        <div class="column is-8">
          <div class="hero-body">
            <div class="columns is-vcentered">
              <div class="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="12" data-height="375" data-nofollow="true" data-expandifr="true" data-scale="100" data-reviews="310656,309004,306408,305768,305677" data-clutchcompany-id="2416069"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="contact section is-fullwidth is-variant4">
      <div class="columns is-centered">
        <div class="column is-8 hero">
          <div class="hero-body">
            <div class="columns is-vcentered">
              <div class="column is-size-4">Let us help unleash creativity for your business!</div>
              <div class="column has-text-right">
                <router-link
                  to="/contact"
                  class="button is-rounded is-dark is-small"
                >Click here to get started</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="career section is-fullwidth is-variant5">
      <div class="columns is-centered">
        <div class="column content is-6 is-size-6 has-text-centered">
          <p>
            Are you interested in joining our team of designers, programmers,
            and engineers dedicated to unleashing creativity for our clients?
          </p>

          <p>
            <router-link
              to="/careers"
              class="button is-rounded is-small is-info"
            >Click here to learn more</router-link>
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import 'particles.js'

export default {
  name: "HomePage",
  components: {
    AppHeader
  },
  data() {
    return {
      questions: [
        "Do we need a fresh design for our business message?",
        "Do we lack the skills and resources to solve this problem?",
        "Are we pressed for time?",
        "Do we have someone we can trust?",
        "Are we facing a challenge to our business potential that could be solved with technology?"
      ]
    }
  },
  mounted() {
    window.particlesJS.load('particles', '/particles.json')
  },
  metaInfo: {
    title: "Vihanti",
    titleTemplate: null,
    meta: [
      { name: "description", content: "We are passionate about unleashing creativity for your business.  When you need a fresh approach, our global team of experienced tech professionals are here for you." }
    ]
  }
}
</script>

<style scoped lang="scss">
#particles {
  position: absolute;
  width: 100%;
  height: 100%;
}

.questions .tag {
  background-color: transparent;
  border: 1px solid $gold;
  color: $gold;
}

.tech {
  @include mobile {
    .logos {
      display: flex;

      .box {
        padding: 3px;
      }

      .column {
        padding: 3px;
      }
    }
  }

  .box {
    height: 100%;
    align-items: center;
    display: inline-flex;
  }
}

.contact {
  .hero {
    background: linear-gradient($variant4-background-color, $variant4-background-color), url("~@/assets/office.jpg");
    background-position: center 90%;
    background-size: cover;
    color: $white;
  }

  .button {
    background-color: transparent;
    border: 1px solid $white;
    color: $white;
  }
}

.career {
  button {
    margin-top: 2em;
  }
}

.inthenews {
  background: linear-gradient(rgba(0, 0, 0, .55), rgba(0, 0, 0, .55)), url('~@/assets/city.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: $white;

  .title {
    color: $white;
  }
}
.clutch-widget {
  width: 100%;
}
</style>
