import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import Buefy from 'buefy'
import '@mdi/font/css/materialdesignicons.css'

import HomePage from '@/pages/Home.vue'
import AboutPage from '@/pages/About.vue'
import CareersPage from '@/pages/Careers.vue'
import ContactPage from '@/pages/Contact.vue'
import PrivacyPage from '@/pages/Privacy.vue'
import ServicesPage from '@/pages/Services.vue'
import TermsPage from '@/pages/Terms.vue'
import NotFound from '@/pages/NotFound.vue'

const routes = [
    { path: '/', component: HomePage, name:HomePage, meta: { title:"Home" }},
    { path: '/about', component: AboutPage, name:AboutPage, meta: { title:"About" }},
    { path: '/careers', component: CareersPage, name:CareersPage, meta: { title:"Careers" }},
    { path: '/contact', component: ContactPage, name:ContactPage, meta: { title:"Contact" }},
    { path: '/privacy', component: PrivacyPage, name:PrivacyPage, meta: { title:"Privacy" }},
    { path: '/services', component: ServicesPage, name:ServicesPage, meta: { title:"Services" }},
    { path: '/terms', component: TermsPage, name:TermsPage, meta: { title:"Terms" }},
    { path: '/:pathMatch(.*)*', component: NotFound, name:NotFound, meta: { title:"Not Found" }}
]
const Router = new createRouter({
    history:createWebHistory(),
    routes,
    scrollBehavior () { 
      return { left: 0, top: 0 }
    }
})

const app = createApp(App)
app.use(Router)
app.use(Buefy)
app.config.productionTip = false
app.mount('#app')
