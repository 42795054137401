<template>
  <b-navbar :transparent="true">
    <template v-slot:brand>
      <b-navbar-item tag="router-link" to="/">
        <img class="logo" src="@/assets/logo.png" alt="Vihanti" />
      </b-navbar-item>
    </template>

    <template v-slot:end>
      <div class="navbar-item tabs">
        <ul>
          <router-link
            v-for="link in links"
            :key="link.to"
            :to="link.to"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <li :class="[isActive && 'is-active']">
              <a class="navbar-item" :href="href" @click="navigate">
                <b-icon :icon="link.icon" />
                {{ link.title }}
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: 'AppNavigation',
  data () {
    return {
      links: [
        { to: '/services', title: 'Services', icon: 'cog' },
        { to: '/about', title: 'About', icon: 'information-outline' },
        { to: '/contact', title: 'Contact', icon: 'at' }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.navbar .logo {
  margin: 1em;
  max-height: 5em;
}

.navbar-item {
  font-family: 'Bank Gothic', BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

  font-size: small;
  text-transform: uppercase;
  margin-right: 1em;
  background-color: #00000000 !important;
}
</style>