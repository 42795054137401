<template>
  <div>
    <app-header>
      <h1 class="title">Services</h1>
    </app-header>

    <section class="section is-fullwidth">
      <div class="columns is-centered">
        <div class="column is-8">
          <div class="columns is-vcentered mb-6">
            <div class="column animated content" data-animated="fadeInLeft">
              <h2 class="title">Frontend</h2>
              <p>
                We take pride in our craft. We believe that modern
                technology ought to be available for small and large
                businesses alike. Whether you need a website to promote your
                business or an e-commerce site to sell your products, we have
                the industry experience to make it happen at a price to match
                your budget. Here are some examples:
              </p>
              <ul>
                <li>First time online business presence, including website and email</li>
                <li>Refresh website for modern, mobile friendly design</li>
                <li>Optimize website for SEO peformance</li>
                <li>Build custom mobile application for iOS and Android</li>
              </ul>
            </div>
            <div class="column is-paddingless">
              <div class="logos animated" data-animated="fadeIn">
                <div class="columns is-flex-mobile is-vcentered has-text-centered">
                  <div class="column">
                    <a href="https://www.wordpress.org">
                      <img src="@/assets/tech/wordpress.png" alt="Wordpress" />
                    </a>
                  </div>
                  <div class="column">
                    <a href="https://flutter.dev/">
                      <img src="@/assets/tech/flutter.png" alt="Flutter" />
                    </a>
                  </div>
                  <div class="column">
                    <a href="https://developer.android.com/compose">
                      <img src="@/assets/tech/jetpack.png" alt="Jetpack" />
                    </a>
                  </div>
                </div>
                <div class="columns is-flex-mobile is-vcentered has-text-centered">
                  <div class="column">
                    <a href="https://react.dev/">
                      <img src="@/assets/tech/react.png" alt="React" />
                    </a>
                  </div>
                  <div class="column">
                    <a href="https://vuejs.org/">
                      <img src="@/assets/tech/vuejs.png" alt="Vuejs" />
                    </a>
                  </div>
                  <div class="column">
                    <a href="https://www.shopify.com">
                      <img src="@/assets/tech/shopify.png" alt="Shopify" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns is-reverse-mobile is-vcentered mb-6">
            <div class="column">
              <div class="logos animated" data-animated="fadeIn">
                <div class="columns is-flex-mobile is-vcentered has-text-centered">
                  <div class="column">
                    <a href="https://www.python.org">
                      <img src="@/assets/tech/python.png" alt="Python" />
                    </a>
                  </div>
                  <div class="column">
                    <a href="https://www.nodejs.org">
                      <img src="@/assets/tech/nodejs.png" alt="Node.js" />
                    </a>
                  </div>
                </div>
                <div class="columns is-vcentered is-flex-mobile has-text-centered">
                  <div class="column">
                    <a href="https://www.scala-lang.org">
                      <img src="@/assets/tech/scala.png" alt="Scala" />
                    </a>
                  </div>
                  <div class="column">
                    <a href="https://firebase.google.com/">
                      <img src="@/assets/tech/firebase.png" alt="Firebase" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="column animated content" data-animated="fadeInRight">
              <h2 class="title">Backend</h2>
              <p>
                We know the fast pace of technology can be overwhelming.  We're
                here to help. Although it's true that we enjoy an occasional
                nerd session, our years of experience has given us a pragmatic
                approach. By listening carefully to what troubles your business
                most, we creatively evaluate the vast array of technology
                options to build a custom solution that reliably delivers real
                world value to your business at an affordable price. While each
                solution will be taylored to your needs, here are some examples
                of ways that we might be able to help you:
              </p>
              <ul>
                <li>Build backend service to support a web frontend or mobile app</li>
                <li>Optimize sales process by integrating inventory directly into e-commerce site</li>
                <li>Automate data transfers between separate systems to reduce opex</li>
              </ul>
            </div>
          </div>
          <div class="columns is-vcentered mb-6">
            <div class="column animated content" data-animated="fadeInLeft">
              <h2 class="title">Embedded</h2>
              <p>
                We have years of experience developing on the latest mobile chipsets and
                operating systems. We understand the importance of security-oriented
                design, and we relish the challenge of bridging the gap between software
                domains to create solutions that deliver real business value.
              </p>
            </div>
            <div class="column is-paddingless">
              <div class="logos animated" data-animated="fadeIn">
                <div class="columns is-vcentered is-flex-mobile has-text-centered">
                  <div class="column is-flex is-justify-content-center is-align-tems-center">
                    <img src="@/assets/tech/embedded.png" alt="Embedded Mobile Development" class="embedded-image"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="contact section is-fullwidth is-variant4">
      <div class="columns is-centered">
        <div class="column is-8 hero">
          <div class="hero-body">
            <div class="columns is-vcentered">
              <div class="column is-size-4">Let's discuss how we can unleash creativity for you!</div>
              <div class="column has-text-right">
                <router-link
                  to="/contact"
                  class="button is-rounded is-dark is-small"
                >Contact Us Today</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section is-fullwidth is-variant2">
      <div class="columns is-centered">
        <div class="column is-8 animated" data-animated="fadeInUp">
          <h2 class="title">Honesty matters</h2>
          <p>
            We're passionate about helping your business grow. Even if you're
            not sure about Vihanti, let's talk. We'll be honest with you, even
            if that means pointing you towards another company that might be a
            better fit for your needs.
          </p>
        </div>
      </div>
    </section>

    <section class="section is-fullwidth is-variant5">
      <div class="columns is-centered">
        <div class="column content is-8 is-small animated" data-animated="fadeIn">
          * Names and logos of services and frameworks used by Vihanti are
          trademarks or registered trademarks of their respective organizations,
          and their use here does not imply an endorsement from these organizations.
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'

export default {
  name: "ServicesPage",
  components: {
    AppHeader
  },
  metaInfo: {
    title: "Services",
    meta: [
      { name: "description", content: "Whether you need a refreshed design for your brand, a custom crafted website, or solutions to your business's most challenging problems, we bring creativity and industry experience." }
    ]
  }
}
</script>

<style lang="scss" scoped>
.logos {
  padding: 0 50px;

  img {
    display: block;
  }
}

.contact {
  .hero {
    background: linear-gradient($variant4-background-color, $variant4-background-color), url("~@/assets/office.jpg");
    background-position: center 90%;
    background-size: cover;
    color: $white;
  }

  .button {
    background-color: transparent;
    border: 1px solid $white;
    color: $white;
  }
}
.embedded-image {
  width: 60%;
}
</style>